import React, { useEffect, useState, Suspense, lazy } from 'react';
import {
  Box,
  Text,
  Flex,
  Icon,
  Stack,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { FaTruck } from 'react-icons/fa';
import { HiMiniChevronRight } from 'react-icons/hi2';
import { RiBox3Line } from 'react-icons/ri';
import { PiKeyReturnLight } from 'react-icons/pi';
import { FaCheck } from 'react-icons/fa6';
import { AiOutlineSafety } from 'react-icons/ai';
import { GiThreeLeaves } from 'react-icons/gi';
import CountryModal from './country-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchShipmentList,
  setSelectedShipment,
} from '../../../redux/productSlice';
import NumberGroupInput from '../../../components/number-group-input';
import { useQuery } from '../../../hooks/useQuery';
import isEmpty from 'is-empty';

const ShippingModal = lazy(() => import('./ship-option'));

const ShippingInfo = ({ fromHome }) => {
  const [qty, setQty] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isShippingOpen,
    onOpen: onShippingOpen,
    onClose: onShippingClose,
  } = useDisclosure();
  const dispatch = useDispatch();
  const query = useQuery();

  const { selectedCountry, country } = useSelector((state) => state.home);
  const { productDetails, selectedShipment } = useSelector(
    (state) => state.product
  );

  const handleApplyCountry = (selectedCountry) => {
    onClose();
  };

  useEffect(() => {
    // const products = productDetails?.products;
    // dispatch(setSelectedShipment(productDetails?.shipment[0]));
    if (productDetails) {
      if (productDetails?.shipment?.length > 0) {
        dispatch(setSelectedShipment(productDetails?.shipment[0]));
      }
    }
  }, [dispatch, country, productDetails]);

  useEffect(() => {
    if (productDetails?.products?.id && query.get('sku_id')) {
      const payload = {
        id: productDetails?.products?.id,
        country: country,
        currency: productDetails?.products?.currency,
        sku_id: query.get('sku_id'),
        quantity: qty,
      };
      dispatch(fetchShipmentList(payload));
    }
  }, [country, qty, query.get('sku_id')]);

  return (
    <Box p={2} borderWidth='1px' borderRadius='lg' boxShadow='sm'>
      <Flex justifyContent='space-between' alignItems='center'>
        <Text fontWeight='600'>Ship to</Text>
        <Text onClick={onOpen} cursor='pointer'>
          {selectedCountry?.name}
        </Text>
      </Flex>

      <Divider my={4} />

      {/* <Text fontWeight='500'>Kwelibuy commitment</Text> */}

      <Box mt={4}>
        <Flex
          pointerEvents={isEmpty(selectedShipment) ? 'none' : 'auto'}
          opacity={isEmpty(selectedShipment) ? 0.4 : 1}
          alignItems='center'
          onClick={onShippingOpen}
          cursor='pointer'
        >
          <Stack>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
              <Flex
                justifyContent={'flex-start'}
                gap={'5px'}
                alignItems={'center'}
              >
                <Icon as={FaTruck} boxSize={4} color='gray.500' />
                <Text fontWeight='600' fontSize='14px'>
                  Shipping:{' '}
                  {selectedShipment?.free_shipping === '1'
                    ? 'Free shipping'
                    : selectedShipment?.shipment_fee
                    ? `${selectedCountry?.currency} ${selectedShipment.shipment_fee}`
                    : ''}
                </Text>
              </Flex>
              <Icon as={HiMiniChevronRight} boxSize={4} color='gray.500' />
            </Flex>
            <Text fontSize='14px' color='gray.600' marginLeft='20px' pr='10px'>
              Delivery: {selectedShipment?.delivery_date}
            </Text>
          </Stack>
        </Flex>
      </Box>

      {!fromHome && (
        <>
          <Box mt={4}>
            <Stack>
              <Flex
                justifyContent={'flex-start'}
                gap={'5px'}
                alignItems={'center'}
              >
                <Icon as={RiBox3Line} boxSize={4} color='gray.500' />
                <Text fontWeight='600' fontSize='14px'>
                  Fast delivery
                </Text>
              </Flex>
            </Stack>
            {/* <Stack mt={2} spacing={1} marginLeft='25px'>
              <Text fontSize='11px'>
                <Icon as={FaCheck} color='green' /> TZS2,700.84 coupon code if
                delayed
              </Text>
              <Text fontSize='11px'>
                <Icon as={FaCheck} color='green' /> Refund if package lost
              </Text>
              <Text fontSize='11px'>
                <Icon as={FaCheck} color='green' /> Refund if items damaged
              </Text>
              <Text fontSize='11px'>
                <Icon as={FaCheck} color='green' /> Refund if no delivery in 30
                days
              </Text>
            </Stack> */}
          </Box>
          {/* <Box>
            <Flex
              justifyContent={'flex-start'}
              gap={'5px'}
              alignItems={'center'}
            >
              <Icon as={PiKeyReturnLight} boxSize={4} color='gray.500' />
              <Text fontWeight='600' fontSize='14px'>
                Free returns within 90 days
              </Text>
            </Flex>
          </Box> */}
          <Box mt={4}>
            <Flex
              justifyContent={'flex-start'}
              gap={'5px'}
              alignItems={'center'}
            >
              <Icon as={AiOutlineSafety} boxSize={4} color='gray.500' />
              <Text fontWeight='600' fontSize='14px'>
                Security & Privacy
              </Text>
            </Flex>
            <Stack mt={2} spacing={1} marginLeft='20px'>
              <Text fontSize='11px' mb={2} color='gray.500'>
                Safe payments: We do not share your personal details with any
                third parties without your consent.
              </Text>
              <Text fontSize='11px' color='gray.500'>
                Secure personal details: We protect your privacy and keep your
                personal details safe and secure.
              </Text>
            </Stack>
          </Box>
          <Box mt={4}>
            <Flex
              justifyContent={'flex-start'}
              gap={'5px'}
              alignItems={'center'}
            >
              <Icon as={GiThreeLeaves} boxSize={4} color='gray.500' />
              <Text fontWeight='600' fontSize='14px'>
                Sustainability at Kwelibuy
              </Text>
            </Flex>
          </Box>
        </>
      )}

      <Box mt='20px' w={{ base: '30%', md: '50%' }} mb='5px'>
        <NumberGroupInput
          value={qty}
          onChange={(value) => {
            setQty(value);
          }}
        />
      </Box>
      <Text
        fontSize='15px'
        mt={3}
        textAlign='center'
        color={
          productDetails?.products?.quantity < 10 ? 'red.500' : 'green.500'
        }
      >
        Only {productDetails?.products?.quantity} left in stock.
      </Text>

      <CountryModal
        isOpen={isOpen}
        onClose={onClose}
        onApply={handleApplyCountry}
      />
      <Suspense fallback='Loading...'>
        <ShippingModal isOpen={isShippingOpen} onClose={onShippingClose} />
      </Suspense>
    </Box>
  );
};

export default ShippingInfo;
