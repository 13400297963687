import React from 'react';
import {
  Box,
  Image,
  Text,
  Flex,
  Badge,
  Button,
  Divider,
} from '@chakra-ui/react';
import styles from './order-card.module.scss';
import { useNavigate } from 'react-router-dom';

const OrderCard = ({ order, onCancel }) => {
  const navigate = useNavigate();
  const actionLabel = () => {
    switch (order.status) {
      case 'created':
        return 'Cancel Order';
      case 'submitted':
        return 'Create Ticket';
        case 'delivered':
          return 'Return Order';
      default:
        break;
    }
  };

  const handleActionClick = (e) => {
    e.stopPropagation();
    
    switch (order.status) {
      case 'created':
        onCancel(order.id);
        break;
      case 'delivered':
        navigate(`/return-order/${order.id}`);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      maxW='sm'
      borderWidth='1px'
      borderRadius='lg'
      overflow='hidden'
      bg='white'
      boxShadow='sm'
      onClick={() => {
        navigate(`/order-detail/${order.id}`);
      }}
      cursor={'pointer'}
    >
      <Flex p='4'>
        <Image
          boxSize='60px'
          objectFit='cover'
          src={order.product_image}
          alt={order.product_name}
          bg='#F3F6F8'
          p={2}
          borderRadius='8px'
        />
        <Flex flexDirection='column' ml={4} justifyContent='space-around'>
          <Text
            className={styles.product_name}
            fontWeight='600'
            fontSize='14px'
          >
            {order?.product_name}
          </Text>
          <Text color='green.500' fontSize='14px' fontWeight='600'>
            {order?.currency}
            {order?.total_amount}
          </Text>
        </Flex>
        <Text fontSize='xs' color='gray.500'>
          Order Id: <strong>{order?.order_no}</strong>
        </Text>
      </Flex>
      <Divider />
      <Flex justifyContent='space-between' gap='3rem' p='4'>
        <Flex flexDir='column'>
          <Text fontSize='sm' color='gray.500'>
            Delivery Address:
          </Text>
          <Text fontSize='12px'>
            {order?.shipping_address?.shipping_address},{' '}
            {order?.shipping_address?.shipping_city},{' '}
            {order?.shipping_address?.shipping_country}
          </Text>
        </Flex>
        <Box>
          <Text fontSize='sm' color='gray.500'>
            {/* {order.deliveryDate.split(' ')[0]} */}
          </Text>
          <Text fontSize='sm' color='gray.500'>
            {/* {order.deliveryDate.split(' ')[1]} */}
          </Text>
        </Box>
      </Flex>
      <Divider />
      <Flex justifyContent='space-between' alignItems='center' p='4'>
        <Flex alignItems='center'>
          <Text>Status:</Text>
          <Badge ml={2} colorScheme={order.status}>
            {order.status}
          </Badge>
        </Flex>
        {order.status !== 'cancelled' && (
          <Button
            // onClick={(e) => {
            //   e.stopPropagation();
            //   onCancel(order.id);
            // }}
            onClick={handleActionClick}
            size='sm'
            colorScheme={order.actionColor}
          >
            {actionLabel()}
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default OrderCard;
