import { useEffect, useLayoutEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/footer';
import { useDispatch } from 'react-redux';
import { fetchProfile } from '../redux/userSlice';
import { Container, useMediaQuery } from '@chakra-ui/react';
import ActionButtons from '../components/action-buttons';
import BottomNavigation from '../components/bottom-navigation';
import AccountHeader from '../components/header/account-header';
const AccountRoutes = () => {
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const dispatch = useDispatch();
  let auth = { token: localStorage.getItem('token') };

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  useEffect(() => {
    if (auth.token) dispatch(fetchProfile());
  }, [auth.token, dispatch]);

  return auth.token ? (
    <Wrapper>
      <AccountHeader />

      <Container maxW='100%' padding={'0 120px'}>
        <Outlet />
      </Container>
      {isSmallerThan767 ? <BottomNavigation /> : <Footer />}

      {!isSmallerThan767 && <ActionButtons />}
    </Wrapper>
  ) : (
    <Navigate to='/' />
  );
};

export default AccountRoutes;
