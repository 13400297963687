import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { lazy, Suspense, useCallback, useEffect } from 'react';
import styles from './profile.module.scss';
import PasswordSetting from './password-setting';
import PersonalInformation from './personal-information';
import AddAddress from './add-address';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddressHandler, setAddressList } from '../../redux/userSlice';
import { fetchAddress } from '../../services/user-service';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import Notifications from './notifications';
const AddressList = lazy(() => import('./address-list'));

const Profile = () => {
  const dispatch = useDispatch();
  const { userData, addressList } = useSelector((state) => state.user);
  const fetchAddressList = useCallback(async () => {
    const response = await fetchAddress(userData?.id);
    if (response.status === 200) {
      dispatch(setAddressList(response.data.data));
    }
  }, [dispatch, userData?.id]);

  useEffectOnce(() => {
    if (userData?.id && addressList.length === 0) fetchAddressList();
  });
  return (
    <Box className={styles.main}>
      <Tabs>
        <TabList borderBottom={'1px solid #f3f3f3'}>
          <Tab fontSize={'12.8px'}>Personal Information</Tab>
          <Tab fontSize={'12.8px'}>Account Settings</Tab>
          <Tab fontSize={'12.8px'}>Address</Tab>
          <Tab fontSize={'12.8px'}>
            Notifications
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PersonalInformation />
          </TabPanel>
          <TabPanel>
            <PasswordSetting />
          </TabPanel>
          <TabPanel>
            <Suspense fallback={<div>Loading...</div>}>
              <AddressList />
            </Suspense>
          </TabPanel>
          <TabPanel>
            <Notifications />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Profile;
