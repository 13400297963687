import React, { useState } from 'react';
import './index.scss';
import { Button, Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setIsLogin } from '../../redux/homeSlice';
import FormInput from '../../components/form-input';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { loginHandler } from '../../redux/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { fetchProfile } from '../../redux/userSlice';

const LoginPage = ({ onClose }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = () => {
    onClose();
  };

  return (
    <div className='login-container'>
      <div className='login-box'>
        <h2>Sign In</h2>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email address')
              .required('Required'),
            password: Yup.string().required('Required'),
          })}
          onSubmit={(values) => {
            setLoading(true);
            dispatch(loginHandler(values)).then((res) => {
              setLoading(false);

              if (res.status === 200) {
                localStorage.clear();
                localStorage.setItem('isAuthenticated', 'true');
                localStorage.setItem('token', res.data.data.token);
                toast({
                  title: 'User logged-in Successfully',
                  status: 'success',
                  duration: 9000,
                  isClosable: true,
                });
                dispatch(fetchProfile());
                onClose();
                navigate('/');
              } else {
                toast({
                  title: res?.response?.data?.message ?? 'Something went wrong',
                  status: 'error',
                  duration: 9000,
                  isClosable: true,
                });
              }
            });
          }}
          validateOnMount
        >
          {({ isValid }) => (
            <Flex flexDir={'column'} as={Form} gap={'1.25em'} mt='1.26rem'>
              <Flex flexDir={'column'} gap={'20px'}>
                <FormInput
                  name='email'
                  type='text'
                  label='Email Address'
                  placeholder='Email Address'
                />

                <FormInput
                  name='password'
                  type='password'
                  label='Password'
                  placeholder='Password'
                />
              </Flex>
              <Text
                onClick={handleForgotPassword}
                textAlign='right'
                marginTop='-15px'
                color='#f41106'
              >
                <Link to='/forgot-password'>Forgot Password?</Link>
              </Text>
              <Button
                variant={'primary'}
                isDisabled={!isValid || loading}
                size={'sm'}
                type='submit'
                w={'200px'}
                // isLoading={isLoading}
                loadingText='Please wait...'
              >
                {loading ? <Spinner size='sm' /> : 'Sign in'}
              </Button>
            </Flex>
          )}
        </Formik>
        <div className='signup-link'>
          <Flex justify={'center'} align={'center'} gap={'7px'}>
            Don't have an account?{' '}
            <Text
              cursor={'pointer'}
              onClick={() => {
                dispatch(setIsLogin(false));
              }}
            >
              Sign Up
            </Text>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
