import axios from 'axios';

export const productDetails = async (
  productId,
  country,
  currency,
  user_id,
  sku_id,
  is_guest
) => {
  try {
    const params = {
      id: productId,
      country,
      currency,
      user_id,
      sku_id,
      is_guest,
    };
    return await axios.get(`/product-details`, { params });
  } catch (error) {
    return error;
  }
};
export const getSubCategories = async (catId, country, currency) => {
  try {
    return await axios.get(
      `category-list?category_id=${catId}&country=${country}&currency=${currency}`
    );
  } catch (error) {
    return error;
  }
};
export const getSearchSuggestion = async (query, country, currency) => {
  try {
    return await axios.get(
      `product-search-suggestion?data=${query}&country=${country}&currency=${currency}`
    );
  } catch (error) {
    return error;
  }
};
export const getProductPriceBySku = async (paylaod) => {
  try {
    return await axios.post(`get-product-price`, paylaod);
  } catch (error) {
    return error;
  }
};

export const recentlyViewed = async (user_id, currency, country) => {
  try {
    return await axios.get(
      `product-recently-view?user_id=${user_id}&currency=${currency}&country=${country}`
    );
  } catch (error) {
    return error;
  }
};

export const getShippingList = async (payload) => {
  try {
    return await axios.post(`get-shipment-list`, payload);
  } catch (error) {
    return error;
  }
};
export const reportProduct = async (payload) => {
  try {
    return await axios.post(`submit-report-product`, payload);
  } catch (error) {
    return error;
  }
};
export const getReportCategory = async () => {
  try {
    return await axios.get('get-report-product-category');
  } catch (error) {
    return error;
  }
};

// id=16663,country=TZ,currency=TZS,sku_id=12000036676610466,quantity=1
