import {
  Box,
  Container,
  Flex,
  Text,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import SubCategories from '../sub-categories';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAllCategories,
  setIsCategoryOpen,
  setSubCategories,
  setSubSubCategories,
} from '../../redux/homeSlice';
import styles from './header.module.scss';

const Categories = () => {
  const dispatch = useDispatch();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const { allCategories } = useSelector((state) => state.home);
  const [catTitile, setCatTitle] = useState('');
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: () => {
      dispatch(setIsCategoryOpen(false));
    },
  });

  useEffect(() => {
    if (allCategories.length > 0) {
      const firstCategory = allCategories[0];
      setCatTitle(firstCategory.name);
      dispatch(setSubCategories(firstCategory.subcat_data));

      const updatedCategories = allCategories.map((item, index) => ({
        ...item,
        isActive: index === 0,
      }));

      dispatch(setAllCategories(updatedCategories));
      dispatch(setSubSubCategories([]));
    }
  }, []);

  return (
    <Box className={styles.all_category} ref={ref}>
      <Container
        maxW='100%'
        padding={isSmallerThan767 ? '0 !important' : '0 120px'}
      >
        <Flex>
          <Box
            minW={isSmallerThan767 ? '130px' : 'auto'}
            width={'25%'}
            borderRight={'1px solid #E2E2E2'}
            pr={isSmallerThan767 ? '0' : '24px'}
            className={styles.categories_parent}
          >
            <Text
              fontSize={isSmallerThan767 ? '13px' : '16px'}
              fontWeight={600}
              lineHeight={'26px'}
              mb='12px'
              ml={isSmallerThan767 ? '0' : '10px'}
            >
              CATEGORIES
            </Text>
            <Flex
              gap={'5px'}
              maxHeight={isSmallerThan767 ? '100%' : '465px'}
              overflowY={'auto'}
              direction='column'
              bg={isSmallerThan767 ? '#F0F0F0' : '#fff'}
              className={styles.categories_child}
            >
              {allCategories.map((item, index) => (
                <Text
                  cursor={'pointer'}
                  onMouseEnter={() => {
                    setCatTitle(item.name);
                    dispatch(setSubCategories(item.subcat_data));
                    const temp = allCategories.map((innerItem, innerIndex) => ({
                      ...innerItem,
                      isActive: innerIndex === index,
                    }));

                    dispatch(setAllCategories(temp));
                    dispatch(setSubSubCategories([]));
                  }}
                  onMouseLeave={() => {
                    const temp = allCategories.map((innerItem, innerIndex) => ({
                      ...innerItem,
                      isActive: innerIndex === index,
                    }));

                    dispatch(setAllCategories(temp));
                  }}
                  pl={isSmallerThan767 ? '5px' : '10px'}
                  py={'5px'}
                  backgroundColor={
                    item.isActive
                      ? isSmallerThan767
                        ? '#fff'
                        : '#FFEFEE'
                      : 'transparent'
                  }
                  fontSize={isSmallerThan767 ? '13px' : '15px'}
                >
                  {item.name}
                </Text>
              ))}
            </Flex>
          </Box>
          <Box flex={1}>
            <SubCategories title={catTitile} setCatTitle={setCatTitle} />
          </Box>
        </Flex>
      </Container>
      <Box
        onClick={() => dispatch(setIsCategoryOpen(false))}
        className={styles.overlay}
      ></Box>
    </Box>
  );
};

export default Categories;
