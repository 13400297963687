import { createSlice } from '@reduxjs/toolkit';
import {
  cancelOrder,
  createOrder,
  getOrderDetails,
  getOrders,
  refundCategory,
  refundorder,
  returnOrder,
} from '../services/order-service';

const initialState = {
  isLoading: false,
  cartList: [],
  orderDetails: {},
  orders: [],
  singleOrderDetails: null,
  shippingDetails: null,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setOrderDetails(state, action) {
      state.orderDetails = { ...state.orderDetails, ...action.payload };
    },
    setOrders(state, action) {
      state.orders = action.payload;
    },
    setSingleOrderDetails(state, action) {
      state.singleOrderDetails = action.payload;
    },
    setShippingDetails(state, action) {
      state.shippingDetails = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setOrderDetails,
  setOrders,
  setSingleOrderDetails,
  setShippingDetails,
} = orderSlice.actions;

export default orderSlice.reducer;

export const createOrderHandler = (data) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const userData = getState().user.userData;
    const guestInfo = getState().guest.guestInfo;
    const currency = getState().home.currency;
    const country = getState().home.country;
    const payload = {
      currency,
      country,
      user_id: userData?.id || guestInfo.id,
      ...data,
    };
    const response = await createOrder(payload);
    dispatch(setIsLoading(false));
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchOrders = () => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const userData = getState().user.userData;
    const guestInfo = getState().guest.guestInfo;
    const user_id = userData?.id || guestInfo.id;
    const response = await getOrders(user_id);
    if (response.status === 200) {
      dispatch(setOrders(response.data.data));
    }
    dispatch(setIsLoading(false));
    return response;
  } catch (error) {
    return error;
  }
};
export const fetchOrderDetails = (orderId) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const userData = getState().user.userData;
    const guestInfo = getState().guest.guestInfo;
    const user_id = userData?.id || guestInfo.id;
    const response = await getOrderDetails(user_id, orderId);
    if (response.status === 200) {
      dispatch(setSingleOrderDetails(response.data.data));
    }
    dispatch(setIsLoading(false));
    return response;
  } catch (error) {
    return error;
  }
};
export const cancelOrderHandler = (orderId) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));

    const response = await cancelOrder(orderId);
    if (response.status === 200) {
      dispatch(fetchOrders());
    }
    dispatch(setIsLoading(false));

    return response;
  } catch (error) {
    return error;
  }
};
export const refundCategoryHandler = () => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const response = await refundCategory();
    dispatch(setIsLoading(false));

    return response;
  } catch (error) {
    return error;
  }
};

export const refundOrderHandler = (payload) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const response = await refundorder(payload);
    if (response.status === 200) {
      dispatch(fetchOrders());
    }
    dispatch(setIsLoading(false));

    return response;
  } catch (error) {
    return error;
  }
};
