import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  IconButton,
  Image,
  Text,
  Tooltip,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  Icon,
  Input,
  useToast,
  useDisclosure,
  Checkbox,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import NumberGroupInput from '../../../components/number-group-input';
import { RiBox3Line, RiDeleteBin2Line, RiHeartLine } from 'react-icons/ri';
import styles from './cart.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCartHandler,
  fetchCartList,
  updateCartQuantityHandler,
} from '../../../redux/cartSlice';
import { useNavigate } from 'react-router-dom';
import {
  calculateDiscountPercentage,
  formatePrice,
} from '../../../utils/functions';
import { PiWarningCircleThin } from 'react-icons/pi';
import { AiOutlineSafety } from 'react-icons/ai';
import { GiThreeLeaves } from 'react-icons/gi';
import { addToWishlistHandler } from '../../../redux/wishlistSlice';
import { GoPackage } from 'react-icons/go';
import ShippingModal from '../../product-detail/logistics/ship-option';
import ProductDetail from '../../product-detail';
import DeleteCartModal from './delete-cart-modal';
import Modal from '../../../components/modal';
import { fetchShipmentList, setProductId } from '../../../redux/productSlice';
import boxIcon from '../../../assets/img/icons/boxIcon.svg';
import heartIcon from '../../../assets/img/icons/heart.svg';
import dustbinIcon from '../../../assets/img/icons/dustbinIcon.svg';

const Cart = () => {
  const {
    isOpen: isShippingOpen,
    onOpen: onShippingOpen,
    onClose: onShippingClose,
  } = useDisclosure();
  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const { cartDetails } = useSelector((state) => state.cart);
  const [cartItems, setCartItems] = useState([]);
  const [coupon, setCoupon] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { currency, country } = useSelector((state) => state.home);
  const [selectedItem, setSelectedItem] = useState(null);
  const [productData, setProductData] = useState(null);
  const [qty, setQty] = useState(null);

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (cartDetails?.data?.length === 0)
      dispatch(fetchCartList(userData?.id || guestInfo.id));
  }, [cartDetails?.data?.length, dispatch, guestInfo.id, userData?.id]);

  useEffect(() => {
    if (cartDetails?.data) {
      setCartItems(cartDetails.data);
    }
  }, [cartDetails]);

  const onClose = () => setIsOpen(false);

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    setIsOpen(true);
  };

  const confirmDelete = () => {
    if (selectedItem) {
      dispatch(deleteCartHandler(selectedItem.id));
      setCartItems(cartItems.filter((item) => item.id !== selectedItem.id));
      onClose();
    }
  };

  // const handleQuantityChange = (item, quantity) => {
  //   setQty(quantity);
  //   const updatedCartItems = cartItems.map((cartItem) =>
  //     cartItem.id === item.id ? { ...cartItem, quantity } : cartItem
  //   );
  //   console.log(updatedCartItems, 'updatedCartItems');

  //   setCartItems(updatedCartItems);
  //   dispatch(updateCartQuantityHandler(item, quantity));
  // };

  const handleQuantityChange = (item, quantity) => {
    dispatch(updateCartQuantityHandler(item, quantity)).then(() => {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.id === item.id ? { ...cartItem, quantity } : cartItem
        )
      );
    });
  };

  const handleAddToWishlistItem = (item) => {
    dispatch(addToWishlistHandler(item));
    toast({
      title: 'Product added to wishlist',
      status: 'success',
      duration: 2000,
      isClosable: true,
      onCloseComplete: () => navigate('/wishlist'),
    });
  };

  const onShipping = (item) => {
    setSelectedItem(item);
    onShippingOpen();
  };

  const cartHandler = (data) => {
    try {
      dispatch(setProductId(data.product_unique_id));
      setProductData(data);
      onOpenCart();
      console.log('cart opened ');
    } catch (error) {
      console.log(error.message, 'error');
    }
  };

  const calculateTotal = (price, quantity) => price * quantity;

  useEffect(() => {
    console.log(selectedItem, "dataaa")
    if (selectedItem?.id) {
      const payload = {
        id: selectedItem?.product_id,
        country: country,
        currency: currency,
        sku_id: selectedItem.sku_id,
        quantity: selectedItem.quantity || 1,
      };
      dispatch(fetchShipmentList(payload));
    }
  }, [country, selectedItem, currency]);

  const handleSelectItem = (item) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(item)) {
        return prevSelected.filter((selectedItem) => selectedItem !== item);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedItems.length === cartItems.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(cartItems);
    }
  };


  console.log(cartDetails, "cart details")
  console.log(cartItems, "cart items")

  return (
    <div style={{ paddingBottom: '20px' }}>
      {cartItems?.length === 0 && (
        <Flex
          flexDir={'column'}
          gap={'10px'}
          justify={'center'}
          align={'center'}
          p='50px'
          minH='500px'
        >
          <Text fontSize={'24px'}>Your cart is empty!</Text>
          <Text>Add items to it now.</Text>
          <Button variant='primary' onClick={() => navigate('/')}>
            Shop Now
          </Button>
        </Flex>
      )}

      {cartItems?.length > 0 && (
        <>
          <Flex py='20px' justify={'space-between'} align={'center'}>
            <Text fontSize={'18px'} fontWeight={600}>
              Cart
            </Text>
          </Flex>
          <Checkbox
            isChecked={selectedItems.length === cartItems.length}
            onChange={handleSelectAll}
            mb='15px'
            ml='5px'
          >
            {selectedItems.length === 0
              ? 'Select All Items'
              : `Selected Products: ${selectedItems.length}`}
          </Checkbox>

          <Flex
            direction={{ base: 'column', lg: 'row' }}
            gap='20px'
            wrap={{ base: 'wrap', lg: 'nowrap' }}
            alignItems='flex-start'
          >
            {/* Main Content */}
            <Container
              minW='78%'
              maxW='100%'
              p='1px'
              borderRadius='0.625rem'
              flexGrow={1}
            >
              <Box
                className={styles.box}
                overflowX='auto'
                borderRadius='0 0 0.625rem 0.625rem'
              >
                <Table className={styles.simple_table}>
                  <Tbody>
                    {cartItems.map((item, index) => (
                      <Flex key={index} borderRadius="8px" mb="10px" bg='white'>
                        <Td w='70%'>
                          <Flex gap={'1rem'}  >
                            <Checkbox
                              isChecked={selectedItems.includes(item.id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedItems((prev) => [...prev, item.id]);
                                } else {
                                  setSelectedItems((prev) => prev.filter((id) => id !== item.id));
                                }
                              }}
                            />
                            <Image
                              cursor={'pointer'}
                              onClick={() => cartHandler(item)}
                              borderRadius={'15px'}
                              height={'90px'}
                              width={'110px'}
                              alignSelf='center'
                              src={
                                item.image ||
                                require('../../../assets/img/product/no-image.png')
                              }
                            />
                            <Flex flexDir={'column'}
                              cursor={'pointer'}
                              onClick={() => cartHandler(item)}>
                              <Tooltip label={item.name}>
                                <Text
                                  fontWeight={600}
                                  fontSize={14}
                                  maxW='450px'
                                  className={styles.product_name}
                                >
                                  {item.name}
                                </Text>
                              </Tooltip>
                              {item.attributes?.map((attr, idx) => (
                                <Flex gap={'5px'} key={idx}>
                                  <Text fontSize='0.813rem' fontWeight={500}>
                                    {attr?.name} :
                                  </Text>
                                  <Text
                                    color='gray'
                                    fontSize='0.813rem'
                                    fontWeight={500}
                                  >
                                    {attr?.value}
                                  </Text>
                                </Flex>
                              ))}
                              <Flex gap={'5px'}>
                                <Box w='auto'>
                                  <Text
                                    fontSize={'15px'}
                                    fontWeight={700}
                                    color={'#000'}
                                    whiteSpace='nowrap'
                                  >
                                    {/* {`${currency} ${item.price}`} */}
                                    {`${currency} ${calculateTotal(
                                      formatePrice(item.price),
                                      item.quantity
                                    ).toLocaleString('en-US')}`}
                                  </Text>
                                </Box>
                                <Badge colorScheme='#F41106' maxW='105px'>
                                  {calculateDiscountPercentage(item.mrp, item.price)}% discount
                                </Badge>
                              </Flex>
                              <Flex gap={'5px'}>
                                {item.shipment === '0' ||
                                  item.shipment === '0.00' ? (
                                  <Text
                                    fontSize='0.813rem'
                                    fontWeight={600}
                                    color='green'
                                  >
                                    Free Shipping
                                  </Text>
                                ) : (
                                  <>
                                    <Text fontSize='0.813rem' fontWeight={500}>
                                      Shipping :
                                    </Text>
                                    <Text
                                      color='gray'
                                      fontSize='0.813rem'
                                      fontWeight={500}
                                    >
                                      {currency} {item?.shipment}
                                    </Text>
                                  </>
                                )}
                              </Flex>
                            </Flex>
                          </Flex>
                        </Td>
                        <Td w='30%' display='flex' flexDirection='column' alignItems='flex-end' justifyContent='center' gap='10px'>
                          <Flex gap={'10px'} mr='-10px'>
                            <IconButton
                              onClick={() => {
                                const _temp = {
                                  ...item,
                                  quantity: qty,
                                };
                                onShipping(_temp);
                              }}
                              aria-label='Shipment'
                              icon={
                                <Image src={boxIcon} alt='Shipment Icon' boxSize='20px' />
                              }
                              variant={'outline'}
                              border='none'
                              fontSize='20px'
                            />
                            <IconButton
                              onClick={() => handleAddToWishlistItem(item)}
                              aria-label='Add to wishlist'
                              icon={
                                <Image src={heartIcon} alt='wishlist Icon' boxSize='20px' />
                              }
                              variant={'outline'}
                              border='none'
                              fontSize='20px'
                            />
                            <IconButton
                              onClick={() => {
                                handleDeleteClick(item);
                                console.log(item, 'product detail');
                              }}
                              aria-label='Delete item'
                              icon={
                                <Image src={dustbinIcon} alt='Delete Icon' boxSize='20px' />
                              }
                              variant={'outline'}
                              border='none'
                              fontSize='20px'
                            />
                          </Flex>
                          <Box w='80px' display='flex' justifyContent='center'>
                            <NumberGroupInput
                              value={item.quantity}
                              onChange={(value) => handleQuantityChange(item, value)}
                            />
                          </Box>
                        </Td>
                      </Flex>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Container>

            {/* Checkout Summary */}
            <Box
              className={styles.box}
              // bg='#666666'
              p='1px'
              minW='23%'
              borderRadius='0.625rem'
              flexGrow={1}
            >
              <Box bg='white' p='10px' borderRadius='0.625rem'>
                <Text
                  mt='5px'
                  fontSize='18px'
                  fontWeight={700}
                  textAlign='start'
                >
                  Summary
                </Text>
                <Flex mt='20px' flexDir={'column'} gap={'.70rem'}>
                  <Flex justify={'space-between'} align={'center'}>
                    <Text color='#8c9097' fontSize='13px'>
                      Sub Total
                    </Text>
                    <Text fontSize='14px'>{cartDetails?.sub_total_amount}</Text>
                  </Flex>
                  <Flex justify={'space-between'} align={'center'}>
                    <Text color='#8c9097' fontSize='13px'>
                      Discount
                    </Text>
                    <Text color={'#26bf94'} fontSize='14px'>
                      {cartDetails?.discount}
                    </Text>
                  </Flex>
                  <Flex justify={'space-between'} align={'center'}>
                    <Text color='#8c9097' fontSize='13px'>
                      Delivery Charges
                    </Text>
                    <Text fontSize='14px'>{cartDetails?.shipment_amount}</Text>
                  </Flex>
                  <Flex justify={'space-between'} align={'center'}>
                    <Text color='#8c9097' fontSize='13px'>
                      Total :
                    </Text>
                    <Text fontWeight={600} fontSize='14px'>
                      {cartDetails?.total_amount}
                    </Text>
                  </Flex>
                </Flex>

                <Box mt={4}>
                  <Flex
                    justifyContent={'flex-start'}
                    gap={'5px'}
                    alignItems={'center'}
                  >
                    <Icon as={RiBox3Line} boxSize={4} color='gray.500' />
                    <Text fontWeight='600' fontSize='14px'>
                      Fast delivery
                    </Text>
                  </Flex>
                </Box>
                <Box mt={4}>
                  <Flex
                    justifyContent={'flex-start'}
                    gap={'5px'}
                    alignItems={'center'}
                  >
                    <Icon as={AiOutlineSafety} boxSize={4} color='gray.500' />
                    <Text fontWeight='600' fontSize='14px'>
                      Security & Privacy
                    </Text>
                  </Flex>
                  <Stack mt={2} spacing={1} marginLeft='25px'>
                    <Text fontSize='11px' mb={2} color='gray.500'>
                      Safe payments: We do not share your personal details with
                      any third parties without your consent.
                    </Text>
                    <Text fontSize='11px' color='gray.500'>
                      Secure personal details: We protect your privacy and keep
                      your personal details safe and secure.
                    </Text>
                  </Stack>
                </Box>
                <Box mt={4}>
                  <Flex
                    justifyContent={'flex-start'}
                    gap={'5px'}
                    alignItems={'center'}
                  >
                    <Icon as={GiThreeLeaves} boxSize={4} color='gray.500' />
                    <Text fontWeight='600' fontSize='14px'>
                      Buyer protection
                    </Text>
                  </Flex>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  maxW='400px'
                  mt='20px'
                >
                  <Box display='flex' alignItems='center' w='100%'>
                    <Input
                      placeholder='Coupon Code'
                      flex='1'
                      borderRightRadius='0'
                      width='100%'
                      border='#e9edf6 1px solid'
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                    />
                    <Button
                      variant='secondary'
                      color='black'
                      maxW='150px'
                      borderLeftRadius='0'
                    // onClick={applyCoupon}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>

                <Button
                  w='100%'
                  mt='20px'
                  variant={'primary'}
                  onClick={() => {
                    navigate('/create/order');
                  }}
                >
                  Proceed to checkout
                </Button>
                <Button
                  w='100%'
                  mt='20px'
                  variant={'secondary'}
                  onClick={() => {
                    navigate('/product/listing');
                  }}
                >
                  Continue Shopping
                </Button>
              </Box>
            </Box>
          </Flex>
        </>
      )}

      <DeleteCartModal
        isOpen={isOpen}
        onClose={onClose}
        confirmDelete={confirmDelete}
      />

      <ShippingModal
        fromCart
        isOpen={isShippingOpen}
        onClose={onShippingClose}
        product={selectedItem}
      />

      <Modal width='95%' p='0 30px' isOpen={isOpenCart} onClose={onCloseCart}>
        <ProductDetail
          fromHome
          sku_id={productData?.sku_id}
          onClose={onCloseCart}
        />
      </Modal>
    </div>
  );
};

export default Cart;
