import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Img,
  Input,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../components/header';
import Banner from '../../components/banner';
import Categories from '../../components/home/categories';
import Deals from '../../components/home/deals';
import TopCategory from '../../components/home/top-category';
import DealBanner1 from '../../assets/img/banner/banner-1.png';
import Recommendations from '../../components/home/recommendations';
import Footer from '../../components/footer';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchNowBought,
  getHomeDataHandler,
  getProductsHandler,
  setPage,
} from '../../redux/homeSlice';
import HotDealBanner from '../../components/deal-banner';
import BestDeals from '../../components/home/deals';
import Modal from '../../components/modal';
import ProductDetail from '../product-detail';
import logo from '../../assets/img/logo.svg';
import { subscribeToNewsLatterHandler } from '../../redux/userSlice';
import { returnOrderHandler } from '../../redux/orderSlice';
import { CloseIcon } from '@chakra-ui/icons';
const dealBanner = [
  {
    url: DealBanner1,
  },
  {
    url: DealBanner1,
  },
  {
    url: DealBanner1,
  },
  {
    url: DealBanner1,
  },
  {
    url: DealBanner1,
  },
  {
    url: DealBanner1,
  },
];

const Homepage = () => {
  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();
  const {
    isOpen: isOpenNewsletter,
    onOpen: onOpenNewsletter,
    onClose: onCloseNewsletter,
  } = useDisclosure();
  const toast = useToast();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const { homeData, isLoading } = useSelector((state) => state.home);
  const [productData, setProductData] = useState(null);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  const isSubscribedToNewsletter = userData?.is_newsletter === 1 ? true : false;
  const userEmail = userData?.email;
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false);


  // const search_key = homeData?.banners[0]?.search_key;
  // const minPrice = homeData?.best_deal_attributes.min_price;
  // const maxPrice = homeData?.best_deal_attributes.max_price;

  // console.log (minPrice,maxPrice , "data mins price")

  useEffect(() => {
    const hasShownNewsletter = localStorage.getItem('hasShownNewsletter');

    if (userData && !isSubscribedToNewsletter && !hasShownNewsletter) {
      // onOpenNewsletter();
      setShowNewsletterPopup(true);
    }
  }, [userData, isSubscribedToNewsletter, onOpenNewsletter]);

  useEffect(() => {
    if (userData || guestInfo?.id) dispatch(getHomeDataHandler());
    return () => dispatch(setPage(1));
  }, [dispatch, guestInfo, guestInfo?.id, userData]);

  const cartHandler = (data) => {
    setProductData(data);
    onOpenCart();
  };

  useEffect(() => {
    dispatch(setPage(1));
  }, [dispatch]);

  // useEffect(() => {
  //   setInterval(() => {
  //     dispatch(fetchNowBought());
  //   }, 120000); 
  // }, [dispatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(fetchNowBought());
    }, 360000); 
    return () => clearInterval(intervalId);
  }, [dispatch]);
  

  const allowSubscribe = () => {
    const payload = { email: userEmail };
    dispatch(subscribeToNewsLatterHandler(payload)).then((res) => {
      if (res.status === 200) {
        toast({
          title: 'Subscribed Successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
        handleCloseNewsletter();
      } else {
        toast({
          title: res?.response?.data?.message ?? 'Something went wrong',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const handleCloseNewsletter = () => {
    localStorage.setItem('hasShownNewsletter', 'true');
    // onCloseNewsletter();
    setShowNewsletterPopup(false);
  };

  return (
    <Box bg='#ffffff'>
      <Categories />

      {isLoading ? (
        <Skeleton height={'316px'} borderRadius={'16px'}></Skeleton>
      ) : (
        <Banner data={homeData.banners} />
      )}

      <TopCategory />
      <HotDealBanner items={dealBanner} slidesPerView={3} spaceBetween={16} />
      <BestDeals
        title={
          <Text
            fontSize={isSmallerThan767 ? '18px' : '24px'}
            fontWeight={700}
            lineHeight={'30px'}
            color={'#666'}
          >
            The <span style={{ color: '#F41106' }}>best deal</span>
          </Text>
        }
        onCartOpen={cartHandler}
      />

      <Recommendations
        title={
          <Text
            fontSize={isSmallerThan767 ? '18px' : '24px'}
            fontWeight={700}
            lineHeight={'30px'}
            color={'#666'}
            mb='30px'
          >
            Recommendations <span style={{ color: '#F41106' }}>For you</span>
          </Text>
        }
        onCartOpen={cartHandler}
      />

      <Modal width='95%' p='0 30px' isOpen={isOpenCart} onClose={onCloseCart}>
        <ProductDetail fromHome sku_id={productData?.sku_id} />
      </Modal>

      {/* Newsletter Popup */}
      {showNewsletterPopup && (
        <Box
          position='fixed'
          top='79px'
          left='123px'
          bg='white'
          p='20px'
          borderRadius='lg'
          boxShadow='lg'
          zIndex={1000}
          width='500px'
        >
          <IconButton
            aria-label='Close Popup'
            icon={<CloseIcon />}
            position='absolute'
            top='10px'
            right='10px'
            onClick={handleCloseNewsletter}
            variant='ghost'
          />

          <Box display='flex' flexDirection='row' alignItems='center'>
            <Img src={logo} width='120px' height='35px' />

            <Box flex='1' ml='20px'>
              <Text fontSize='20px' fontWeight='bold'>
                Subscribe to notifications
              </Text>
              <Text fontSize='14px' color='gray.500' mt='5px'>
                Get order updates, tips about the latest discounts, coupons, and
                more!
              </Text>

              <Box mt='10px' display='flex' justifyContent='flex-start'>
                <Button
                  variant='primary'
                  onClick={allowSubscribe}
                  borderRadius='full'
                  mr='10px'
                  px='30px'
                >
                  Allow
                </Button>
                <Button
                  variant='secondary'
                  onClick={handleCloseNewsletter}
                  borderRadius='full'
                  px='20px'
                >
                  Don't Allow
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Homepage;
