import React from 'react';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  SimpleGrid,
  Text,
  VStack,
  Divider,
  Checkbox,
  Radio,
  RadioGroup,
  Spacer,
  useToast,
  useMediaQuery,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { FaCreditCard } from 'react-icons/fa';
import { MdAccountCircle, MdCreditCard } from 'react-icons/md';
import OrderSummary from '../order-summary';
import FormInput from '../../../components/form-input'; // Adjust the path if needed
import { useDispatch, useSelector } from 'react-redux';
import { ezypayHandler } from '../../../redux/paymentSlice';
import isEmpty from 'is-empty';
import { createOrderHandler, setOrderDetails } from '../../../redux/orderSlice';

const Payment = ({ setTabIndex }) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const { orderDetails } = useSelector((state) => state.order);
  const { cartDetails } = useSelector((state) => state.cart);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  const orderHandler = () => {
    const {
      shipping: {
        shipping_name,
        shipping_email,
        shipping_phone,
        shipping_address,
        shipping_pincode,
        shipping_city,
        shipping_state,
        shipping_country,
        billing_name,
        billing_email,
        billing_phone,
        billing_address,
        billing_pincode,
        billing_city,
        billing_state,
        billing_country,
      },
    } = orderDetails;
    const payload = {
      subTotal: cartDetails?.sub_total_amount,
      total: cartDetails?.total_amount,
      shipping: cartDetails?.shipment_amount,
      payment_method: 'ONLINE',
      shipping_name,
      shipping_email,
      shipping_phone,
      shipping_address,
      shipping_pincode,
      shipping_city,
      shipping_state,
      shipping_country,
      billing_name: billing_name || shipping_name,
      billing_email: billing_email || shipping_email,
      billing_phone: billing_phone || shipping_pincode,
      billing_address: billing_address || shipping_address,
      billing_pincode: billing_pincode || shipping_pincode,
      billing_city: billing_city || shipping_city,
      billing_state: billing_state || shipping_state,
      billing_country: billing_country || shipping_country,
      phone_code: '+61',
    };
    dispatch(createOrderHandler(payload))
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem('cartDetails');
          dispatch(setOrderDetails({ paymentDone: true }));
          setTabIndex(2);
          // toast({
          //   title: 'Order placed successfully!',
          //   status: 'success',
          // });
        }
      })
      .catch((error) => {
        toast({
          title: error.message || 'Something went wrong!',
          status: 'error',
        });
      });
  };

  return (
    <>
      <Box
        className='checkout-border'
        backgroundColor='white'
        width={isSmallerThan767 ? '100%' : '70%'}
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            deliveryAddress: !isEmpty(orderDetails?.shipping)
              ? `${orderDetails?.shipping?.shipping_address}, ${orderDetails?.shipping?.shipping_city}, ${orderDetails?.shipping?.shipping_state}, ${orderDetails?.shipping?.shipping_country}`
              : '',
            isChangeAddress: false,
            paymentType: 'ezypay',
            cardNumber: '',
            nameOnCard: '',
            expirationDate: '',
            cvv: '',
            otp: '',
            savedCard: '7646', // Default selected card
          }}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <VStack p={4} align='start'>
                <Text fontSize='1.5rem' fontWeight='bold' color='gray.200'>
                  03
                </Text>
                <Text fontSize='lg' fontWeight='bold'>
                  Payment Details
                </Text>

                <Box display='flex' alignItems='center' w='100%'>
                  <Input
                    label='Delivery Address'
                    name='deliveryAddress'
                    placeholder=''
                    readOnly={!values.isChangeAddress}
                    borderRadius='10px 0 0 10px'
                    value={values.deliveryAddress}
                  />
                  <Button
                    h='40px'
                    borderRadius='0 10px 10px 0'
                    onClick={() => {
                      setFieldValue('isChangeAddress', !values.isChangeAddress);
                    }}
                  >
                    {values.isChangeAddress ? 'Save' : 'Change'}
                  </Button>
                </Box>

                <Box
                  width='100%'
                  p={4}
                  border='1px'
                  borderColor='gray.200'
                  borderRadius='md'
                >
                  <Flex flexDir='row'>
                    <Box
                      width='5px'
                      height='25px'
                      bgGradient='linear(to-b, #B5B8F0, #A5C7F1)'
                      mr={2}
                    />
                    <Text fontSize='md' fontWeight='bold' mb={2}>
                      Payment Methods
                    </Text>
                  </Flex>
                  <Divider borderColor='gray.200' mb={4} />

                  <Flex marginX='auto' width='97%'>
                    <SimpleGrid
                      columns={{ base: 1, md: 3 }}
                      width='100%'
                      mb={4}
                      justifyItems='center'
                      gap='10px'
                    >
                      <Button
                        variant={
                          values.paymentType === 'ezypay'
                            ? 'primary'
                            : 'secondary'
                        }
                        width='100%'
                        onClick={() => {
                          setFieldValue('paymentType', 'ezypay');
                          orderHandler();
                        }}
                      >
                        Ezypay
                      </Button>
                      <Button
                        variant={
                          values.paymentType === 'paypal'
                            ? 'primary'
                            : 'secondary'
                        }
                        width='100%'
                        onClick={() => {
                          setFieldValue('paymentType', 'paypal');
                        }}
                      >
                        PayPal
                      </Button>
                      <Button
                        width='100%'
                        variant={
                          values.paymentType === 'card'
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={() => {
                          setFieldValue('paymentType', 'card');
                        }}
                      >
                        Credit/Debit Card
                      </Button>
                    </SimpleGrid>
                  </Flex>
                </Box>

                {/* <hr />
                <Flex
                  gap={2}
                  mt={10}
                  borderTop='#EDEDED 1px solid'
                  pt={5}
                  width='100%'
                  justifyContent='space-between'
                  flexDir={{ base: 'column', md: 'row' }}
                >
                  <Button
                    leftIcon={<MdAccountCircle />}
                    color='#E6533C'
                    backgroundColor='#FCEDEB'
                    _hover={{ bg: '#E6533C', color: 'white' }}
                  >
                    Back To Personal Info
                  </Button>
                  <Button
                    leftIcon={<MdCreditCard />}
                    color='#26BF94'
                    backgroundColor='#E9F8F4'
                    _hover={{ bg: '#26BF94', color: 'white' }}
                    type='submit'
                  >
                    Continue Payment
                  </Button>
                </Flex> */}
              </VStack>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default Payment;
