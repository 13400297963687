import { Flex, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { BsChevronUp } from 'react-icons/bs';
import { PiChatCircleDotsLight } from 'react-icons/pi';
import { RiEdit2Line } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import AddNewSuggestion from '../add-new-suggestion';
import ReportProductModal from '../../pages/product-detail/report-product';

const ActionButtons = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const {
    isOpen: isOpenSuggestions,
    onOpen: onOpenSuggestions,
    onClose: onCloseSuggestions,
  } = useDisclosure();

  const {
    isOpen: isOpenReportProduct,
    onOpen: onOpenReportProduct,
    onClose: onCloseReportProduct,
  } = useDisclosure();

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1200) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour 
             in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  const handlePencilClick = () => {
    if (location.pathname.startsWith('/product/details')) {
      onOpenReportProduct();
      console.log('report product');
    } else {
      onOpenSuggestions();
    }
  };

  return (
    <>
      <Flex
        flexDir={'column'}
        gap={'10px'}
        pos={'fixed'}
        right={'40px'}
        bottom={'40px'}
        zIndex={1}
      >
        <Flex
          border={'2px solid #ddd'}
          height={'40px'}
          width={'40px'}
          borderRadius={'50%'}
          fontSize={'20px'}
          align={'center'}
          justify={'center'}
          cursor={'pointer'}
          onClick={() => navigate('/qna')}
        >
          <PiChatCircleDotsLight />
        </Flex>
        <Flex
          border={'2px solid #ddd'}
          height={'40px'}
          width={'40px'}
          borderRadius={'50%'}
          fontSize={'20px'}
          align={'center'}
          justify={'center'}
          cursor={'pointer'}
          // onClick={onOpenSuggestions}
          onClick={handlePencilClick}
        >
          <RiEdit2Line />
        </Flex>
        {visible && (
          <Flex
            border={'2px solid #ddd'}
            height={'40px'}
            width={'40px'}
            borderRadius={'50%'}
            fontSize={'20px'}
            align={'center'}
            justify={'center'}
            cursor={'pointer'}
            onClick={scrollToTop}
          >
            <BsChevronUp />
          </Flex>
        )}
      </Flex>
      <AddNewSuggestion
        isOpen={isOpenSuggestions}
        onClose={onCloseSuggestions}
      />

      <ReportProductModal
        isOpen={isOpenReportProduct}
        onClose={onCloseReportProduct}
        productId={''}
      />
    </>
  );
};

export default ActionButtons;
