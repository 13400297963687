import {
  Container,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  RiBankLine,
  RiCheckboxCircleLine,
  RiCheckboxLine,
  RiTruckLine,
  RiUser2Line,
  RiUserLine,
} from 'react-icons/ri';
import ShippingForm from '../checkout/shipping-address';
import PersonalDetail from '../checkout/personal-detail';
import Payment from '../checkout/payment';
import PaymentStatus from '../checkout/payment-status';
import OrderDetails from './order-details';
import { useSelector } from 'react-redux';
import isEmpty from 'is-empty';

const CreateOrder = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { orderDetails } = useSelector((state) => state.order);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  return (
    <>
      <Flex py='20px' justify={'space-between'} align={'center'}>
        <Text fontSize={'18px'} fontWeight={600}>
          Checkout
        </Text>
      </Flex>
      <Tabs
        mt='30px'
        index={tabIndex}
        onChange={(index) => {
          if (
            isEmpty(orderDetails.shipping) ||
            isEmpty(orderDetails.paymentDone)
          )
            return;
          setTabIndex(index);
        }}
      >
        <TabList justifyContent={'space-around'} alignSelf='center'>
          <Tab>
            <RiTruckLine /> <Text ml='10px'>Shipping</Text>
          </Tab>
          {/* <Tab>
            <RiUserLine /> <Text ml='10px'>Personal Details</Text>
          </Tab> */}
          <Tab>
            {' '}
            <RiBankLine /> <Text ml='10px'>Payment</Text>
          </Tab>
          <Tab>
            <RiCheckboxCircleLine /> <Text ml='10px'>Confirmation</Text>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex
              mb={isSmallerThan767 ? '45px' : '0'}
              flexDir={isSmallerThan767 ? 'column' : 'row'}
              gap={'1.25rem'}
            >
              <ShippingForm setTabIndex={setTabIndex} />
              <OrderDetails />
            </Flex>
          </TabPanel>
          {/* <TabPanel>
            <Flex gap={'1.25rem'}>
              <PersonalDetail setTabIndex={setTabIndex} />
              <OrderDetails />
            </Flex>
          </TabPanel> */}
          <TabPanel>
            <Flex
              mb={isSmallerThan767 ? '45px' : '0'}
              gap={'1.25rem'}
              flexDir={isSmallerThan767 ? 'column' : 'row'}
            >
              <Payment setTabIndex={setTabIndex} />
              <OrderDetails />
            </Flex>
          </TabPanel>
          <TabPanel>
            <PaymentStatus />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default CreateOrder;
