import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Skeleton,
  Spinner,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../product-card';
import {
  getProductsHandler,
  setAllProducts,
  setPage,
  setProductCard,
  setProducts,
} from '../../redux/homeSlice';
import { useQuery } from '../../hooks/useQuery';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import { useEffectOnce } from '../../hooks/useEffectOnce';

const ProductListing = ({ fromHome, onCartOpen, fromListing }) => {
  const {
    products,
    productList,
    allProducts,
    hasMore,
    totalResults,
    page,
    isProductLoading,
  } = useSelector((state) => state.home);
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const { filters, isLoading } = useSelector((state) => state.product);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [isSmallerThan1185] = useMediaQuery('(max-width: 1185px)');
  const [isSmallerThan1430] = useMediaQuery('(max-width: 1430px)');
  const [isSmallerThan1530] = useMediaQuery('(max-width: 1430px)');
  const [isSmallerThan1800] = useMediaQuery('(max-width: 1800px)');
  const [isSmallerThan1920] = useMediaQuery('(max-width: 1920px)');
  const dispatch = useDispatch();
  const query = useQuery();
  const categoryId = query.get('category_id');
  const page_no = query.get('page_no');
  const min_discount = query.get('min_discount');
  const max_discount = query.get('max_discount');
  const is_free_shipping = query.get('is_free_shipping');
  const is_sold = query.get('is_sold');
  const search_key = query.get('search_key');
  const key = query.get('key');
  const location = useLocation();

  const [scrollCount, setScrollCount] = useState(0);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [isAPICalled, setIsAPICalled] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const fetchData = useCallback(() => {
    setIsFetching(true);
    dispatch(setAllProducts([]));

    const params = {
      page_no: page,
      categoryId,
      min_discount,
      max_discount,
      is_free_shipping: is_free_shipping,
      is_sold,
      rating: filters?.rating,
      best_match: filters?.best_match,
      order: filters?.order || undefined,
      isPrice: filters?.isPrice || undefined,
      sale: filters?.sale ? 1 : undefined,
      min_price: filters?.min,
      max_price: filters?.max,
    };
    if (search_key) {
      params.search_key = search_key;
    }
    if (key) {
      params.key = key;
    }
    if (fromHome) {
      params.is_home = 1;
    }
    if (userData?.id || guestInfo?.id) {
      dispatch(getProductsHandler(params)).then(() => {
        setIsFetching(false); 
      });
    }
    dispatch(setPage(2));
  }, [categoryId, userData?.id, guestInfo?.id, filters, search_key, key]);

  useEffectOnce(() => {
    fetchData();
    setIsAPICalled(true);
  });

  useEffect(() => {
    if (isAPICalled) {
      fetchData();
    }

    return () => dispatch(setPage(1));
  }, [fetchData, dispatch]);

  const fetchMoreData = () => {
    if (isFetching) return; 
  
    setIsFetching(true); 
    dispatch(setPage(page + 1)); 
  
    const params = {
      page_no: page,
      categoryId,
      min_discount,
      max_discount,
      is_free_shipping: is_free_shipping,
      is_sold,
      rating: filters?.rating,
      best_match: filters?.best_match,
      order: filters?.order || undefined,
      isPrice: filters?.isPrice || undefined,
      sale: filters?.sale ? 1 : undefined,
      min_price: filters?.min,
      max_price: filters?.max,
    };
    if (search_key) {
      params.search_key = search_key;
    }
    if (key) {
      params.key = key;
    }
    if (fromHome) {
      params.is_home = 1;
    }
    if (userData?.id || guestInfo?.id) {
      dispatch(getProductsHandler(params, 'more')).then((res) => {
        if (res.status === 200) {
          setScrollCount((prevScrollCount) => prevScrollCount + 1); // Increment scroll count after fetch
  
          if (scrollCount + 1 >= 4) {
            setShowMoreButton(true);
          }
        }
        setIsFetching(false); 
      });
    }
  };

  const handleShowMore = () => {
    setScrollCount(0);   
    setShowMoreButton(false); 
    fetchMoreData();     
  };

  const productsData = fromHome ? products : productList;

  const slidesPerView = location.pathname.includes('/product/listing')
    ? isSmallerThan767
      ? 2
      : 4
    : isSmallerThan767
      ? 2
      : isSmallerThan1185
        ? 3
        : isSmallerThan1430
          ? 4
          : isSmallerThan1530
            ? 5
            : isSmallerThan1800
              ? 6
              : isSmallerThan1920
                ? 6
                : 6;

  let gridTemplate = productsData.length > 4 ? (!fromHome ? 'auto-fit' : 6) : 4;
  return (
    <>
      {!isProductLoading && totalResults === 0 && allProducts.length === 0 && (
        <Text fontSize={'18px'} p='5' textAlign={'center'}>
          No Product found!
        </Text>
      )}

{isProductLoading && allProducts.length === 0 ? (
        <Flex justify={'center'} align={'center'} padding={'25px'}>
          <Spinner />
        </Flex>
      ) : (
        <InfiniteScroll
          dataLength={allProducts.length}
          next={scrollCount < 4 && fetchMoreData}
          hasMore={allProducts.length < totalResults || scrollCount < 4}
          loader={
            totalResults !== 0 && scrollCount < 4 && isFetching && ( 
              <Flex justify={'center'} align={'center'} padding={'25px'}>
                <Spinner />
              </Flex>
            )
          }
        >
          <SimpleGrid
            columns={slidesPerView}
            gridTemplateColumns={`repeat(${slidesPerView}, minmax(${isSmallerThan767 ? '175px' : '210px'
              }, 1fr))`}
            spacing={'13px'}
            mb='20px'
          >
            {' '}
            {allProducts?.map((item, index) => (
              <ProductCard
                onCartOpen={() =>
                  fromListing ? onCartOpen(item.sku_id) : onCartOpen()
                }
                item={item}
                onMouseEnter={() => {
                  const _temp = [...products];
                  const map = products.map((item, itemIndex) => {
                    if (index === itemIndex) {
                      return { ...item, showIcons: true };
                    }
                    return { ...item, showIcons: false };
                  });
                  dispatch(setProductCard(map));
                }}
                onMouseLeave={() => {
                  const _temp = [...products];
                  const map = _temp.map((item, itemIndex) => {
                    if (index === itemIndex) {
                      return { ...item, showIcons: false };
                    }
                    return { ...item, showIcons: false };
                  });
                  dispatch(setProductCard(map));
                }}
                fromListing
              />
            ))}
          </SimpleGrid>
        </InfiniteScroll>
      )}
     

      {scrollCount === 4 && (
        <Button
          cursor='pointer'
          fontSize={18}
          fontWeight={600}
          textAlign='center'
          textDecoration='underline'
          variant='secondary'
          onClick={handleShowMore}
          colorScheme='teal'
          m='20px'
          mx='auto'
          display='block'
          border='none'
        >
          View More
        </Button>
      )}
    </>
  );
};

export default ProductListing;
