import {
    Avatar,
    Box,
    Button,
    Flex,
    Text,
    useToast,
    Switch,
  } from '@chakra-ui/react';
  import React, { useEffect, useState } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
import { newsletterSubscribeHandler, newsletterUnsubscribeHandler } from '../../redux/homeSlice';
import { fetchProfile } from '../../redux/userSlice';
  
  const Notifications = () => {
    const dispatch = useDispatch();
    const toast = useToast();
    const { userData } = useSelector((state) => state.user);
    const [isSubscribed, setIsSubscribed] = useState(userData?.is_newsletter === 1); 
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [initialState, setInitialState] = useState({
      isSubscribed: userData?.is_newsletter === 1,
    });
    const email = userData?.email || '';
  
    useEffect(() => {
      if (isSubscribed !== initialState.isSubscribed) {
        setIsSaveDisabled(false);
      } else {
        setIsSaveDisabled(true);
      }
    }, [isSubscribed, initialState]);
  
    const handleToggle = () => {
      setIsSubscribed(!isSubscribed);
    };
  
    const handleSaveChanges = () => {
      const payload = { email };
  
      if (isSubscribed) {
        dispatch(newsletterSubscribeHandler(payload)).then((res) => {
          if (res.status === 200) {
            toast({
              title: res?.data?.message || 'Subscribed successfully',
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
            dispatch(fetchProfile());
            setInitialState({ isSubscribed: true });
            localStorage.setItem('hasShownNewsletter', 'true'); 
          } else {
            toast({
              title: res?.response?.data?.message || 'Subscription failed',
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
          }
        });
      } else {
        dispatch(newsletterUnsubscribeHandler(payload)).then((res) => {
          if (res.status === 200) {
            toast({
              title: res?.data?.message || 'Unsubscribed successfully',
              status: 'success',
              duration: 4000,
              isClosable: true,
            });
            dispatch(fetchProfile());
            setInitialState({ isSubscribed: false });
            localStorage.removeItem('hasShownNewsletter');
          } else {
            toast({
              title: res?.response?.data?.message || 'Unsubscription failed',
              status: 'error',
              duration: 4000,
              isClosable: true,
            });
          }
        });
      }
    };
  
    return (
      <Box p={'32px'} borderRadius={'4px'} border={'1px solid #f3f3f3'}>
        <Flex justify={'space-between'} alignItems={'center'} mb={4}>
          <Box>
            <Text fontWeight="bold">Updates & Features</Text>
            <Text fontSize="sm" color="gray.500">
              Notifications about new updates and their features.
            </Text>
          </Box>
          <Switch
            isChecked={isSubscribed}
            onChange={handleToggle}
            size="lg"
            colorScheme="red"
          />
        </Flex>
  
        <Flex mt='100px' justify={'flex-end'}>
          <Button
            variant={'primary'}
            size={'sm'}
            w={'200px'}
            loadingText='Please wait...'
            onClick={handleSaveChanges}
            isDisabled={isSaveDisabled}
          >
            Save Changes
          </Button>
        </Flex>
      </Box>
    );
  };
  
  export default Notifications;
  