import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { accountVerification } from '../../services/auth-service';
import { Image, Box, Text, Flex, Spinner, Button } from '@chakra-ui/react';
import Verified from '../../../src/assets/img/verified.png';
import Failed from '../../../src/assets/img/failed.png';
import PageNotFound from '../page-not-found';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import isEmpty from 'is-empty';

let count = 1;

const AccountVerification = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const verifyAccount = async () => {
    count++;
    setIsLoading(true);
    try {
      const response = await accountVerification(token);

      if (response.status === 200) {
        console.log(response, 'resData');
        setIsVerified(true);
      } else {
        setIsVerified(false);
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Verification failed:', error);

      setIsLoading(false);
    }
  };
  useEffectOnce(() => {
    if (count === 1) {
      verifyAccount();
    }
  });

  console.log(isVerified, 'isVerified');

  return (
    <Box textAlign='center' mt='50px'>
      {isLoading && (
        <Flex justifyContent='center' alignItems='center' height='100vh'>
          <Spinner size='xl' />
        </Flex>
      )}
      {isVerified && (
        <Flex
          width='100%'
          justifyContent='center'
          alignItems='center'
          flexDir='column'
          padding='50px'
        >
          <Image src={Verified} alt='Verified' mx='auto' width='10%' />
          <Text fontSize='xl' mt='20px'>
            User Verified Successfully! Please Login
          </Text>
        </Flex>
      )}
      {!isVerified && !isLoading && <PageNotFound />}
    </Box>
  );
};

export default AccountVerification;
