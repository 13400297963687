import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import GlobeComponentIcon from '../icons/globe-icon';
import SettingIcon from '../icons/setting-icon';
import NotificationIcon from '../icons/notification-icon';
import { useSelector } from 'react-redux';

const AccountHeader = () => {
  const { userData } = useSelector((state) => state.user);
  return (
    <Flex padding={'13px 20px'} justify={'space-between'}>
      <Text fontSize={'18px'} fontWeight={600}>
        {userData?.name}
      </Text>
      <Flex gap={'18px'}>
        <Box>
          <GlobeComponentIcon />
        </Box>
        <Box>
          <SettingIcon />
        </Box>
        <Box>
          <NotificationIcon />
        </Box>
      </Flex>
    </Flex>
  );
};

export default AccountHeader;
