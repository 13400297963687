import React, { useState } from 'react';
import './index.scss';
import ProductListing from '../../components/product-listing';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Select,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import Filters from '../../components/filters';
import SortBy from '../../components/sortby';
import Modal from '../../components/modal';
import ProductDetail from '../product-detail';
import { FaFilter } from 'react-icons/fa';
import { useQuery } from '../../hooks/useQuery';
import { useSearchParams } from 'react-router-dom';

const Listing = () => {
  const [searchParams] = useSearchParams();

  const categoryId = searchParams.get('category_id');
  const title = searchParams.get('title');

  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();
  const {
    isOpen: isOpenFilter,
    onOpen: onOpenFilter,
    onClose: onCloseFilter,
  } = useDisclosure();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [skuId, setSkuId] = useState('');
  const btnRef = React.useRef();
  const query = useQuery();

  const cartOpen = (id) => {
    setSkuId(id);
    onOpenCart();
  };

  console.log(title, 'searchParams');

  return (
    <>
      {query.get('title') && (
        <>
          <Flex padding={'20px'} justify={'center'}>
            <Text>{query.get('title').replace('and', '&')}</Text>
          </Flex>
          <hr />
        </>
      )}
      <div className='product-listing'>
        {!isSmallerThan767 && (
          <Box width={'15%'} p={'25px 0px 25px 0'} minW={'245px'}>
            <Filters />
          </Box>
        )}

        <Box p={isSmallerThan767 ? '0' : '25px 0 0 25px'} flex={1}>
          <SortBy onFilterOpen={onOpenFilter} />

          <ProductListing fromListing onCartOpen={cartOpen} />
        </Box>
        <Modal width='95%' p='0 30px' isOpen={isOpenCart} onClose={onCloseCart}>
          <ProductDetail fromHome sku_id={skuId} />
        </Modal>

        <Drawer isOpen={isOpenFilter} placement='right' onClose={onCloseFilter}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Filter</DrawerHeader>

            <DrawerBody>
              <Filters />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </div>
    </>
  );
};

export default Listing;
