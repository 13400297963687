import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAccountPage } from '../../redux/homeSlice';
import { Box, Flex, Text } from '@chakra-ui/react';
import BoxIcon from '../../components/icons/box-icon';
import ArrowIcon from '../../components/icons/arrow-icon';
import WishlistIcon from '../../components/icons/wishlist-icon';
import UserIcon from '../../components/icons/user-icon';
import ClockIcon from '../../components/icons/clock-icon';
import NotificationIcon from '../../components/icons/notification-icon';
import HelpIcon from '../../components/icons/help-icon';
import HeartIcon from '../../components/icons/heart-icon';
import { useNavigate } from 'react-router-dom';

const items = [
  {
    name: 'My Orders',
    icon: <BoxIcon />,
    path: '/my-orders',
  },
  {
    name: 'My Wishlist',
    icon: <HeartIcon />,
    path: '/wishlist',
  },
  {
    name: 'My Details',
    icon: <UserIcon />,
    path: '/profile',
  },
  {
    name: 'Recently Viewed',
    icon: <ClockIcon />,
    path: '/recently-viewed',
  },
  {
    name: 'Notifications',
    icon: <NotificationIcon />,
  },
  {
    name: 'Help Center',
    icon: <HelpIcon />,
  },
];

const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     dispatch(setIsAccountPage(true));
  //     return () => {
  //       dispatch(setIsAccountPage(false));
  //     };
  //   }, []);

  return (
    <Flex
      bg='#F0F0F0'
      height={'calc(100vh - 115px)'}
      gap={'8px'}
      flexDir={'column'}
      padding={'10px'}
    >
      {items.map((item, index) => (
        <Flex
          padding={'20px 30px'}
          bg='#fff'
          key={index}
          justify={'space-between'}
          align={'center'}
          cursor={'pointer'}
          onClick={() => navigate(item.path)}
        >
          <Flex gap={'16px'} align={'center'}>
            {item.icon}
            <Text fontSize={'16px'} fontWeight={600}>
              {item.name}
            </Text>
          </Flex>
          <Box>
            <ArrowIcon />
          </Box>
        </Flex>
      ))}
    </Flex>
  );
};

export default Account;
