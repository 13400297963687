import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Box,
  Button,
  Image,
  Select,
  Textarea,
  Input,
  useToast,
  Badge,
  Heading,
  RadioGroup,
  Stack,
  Radio,
  FormControl,
  FormLabel,
  HStack
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders, refundCategoryHandler, refundOrderHandler } from '../../redux/orderSlice';
import NumberGroupInput from '../../components/number-group-input';

const ReturnOrder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const { orders } = useSelector((state) => state.order);
  const [orderId, setOrderId] = useState('');
  const [reason, setReason] = useState('');
  const [problemDescription, setProblemDescription] = useState('');

  const [order, setOrder] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [refundCategory, setRefundCategory] = useState(null);

  const [files, setFiles] = useState([]);
  const [refundMethod, setRefundMethod] = React.useState('');
  const [email, setEmail] = React.useState('');

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  useEffect(() => {
    if (orders.length > 0) {
      const foundOrder = orders.find((order) => order.id === Number(id));
      setOrder(foundOrder);
      setOrderId(foundOrder.id);
    }
  }, [orders, id]);

  useEffect(() => {
    refundCategoryOptions();
  }, []);

  const refundCategoryOptions = () => {
    dispatch(refundCategoryHandler()).then((res) => {
      if (res.status === 200) {
        setRefundCategory(res.data.data);
        toast({
          title: 'Data Fetched Successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: res?.response?.data?.message ?? 'Something went wrong',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  useEffect(() => {
    dispatch(fetchOrders()).then((res) => {
      if (res.payload && res.payload.length > 0) {
        setSelectedOrder(res.payload[0]);
      }
    });
  }, [dispatch]);

  const moveToFirstStep = () => {
    setCurrentStep(1);
  };

  const moveToSecondStep = () => {
    if (orderId && files && reason) {
      setCurrentStep(2);
    } else {
      toast({
        title: 'Please fill all required fields',
        status: 'warning',
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    if (uploadedFiles.length > 0) {
      setFiles([...files, ...uploadedFiles]);
    }
  };

  const submitRefundOrder = async () => {
    const formData = new FormData();

    formData.append('order_id', orderId);
    formData.append('reason', reason);
    formData.append('return_method', refundMethod);
    formData.append('email', email);
    files.forEach((file, index) => {
      formData.append(`return_image[${index}]`, file);
    });

    try {
      const res = await dispatch(refundOrderHandler(formData));
      if (res.status === 200) {
        toast({
          title: 'Refund request submitted successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      } else {
        toast({
          title: res?.response?.data?.message ?? 'Something went wrong',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: error.message ?? 'An error occurred',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };



  console.log(order, "dataaa")

  return (
    <Box mb="30px" p="20px">
      <Text fontSize="xl" fontWeight={600} mb="20px">
        Request a Return/Refund for
      </Text>

      <Flex bg='#fffb2e6e' h='50px' p='15px' justifyContent='flex-start' alignItems='center'>
        <Text>
          <Badge bg='#d69e206e' h='20px' m='5px' border='brown 1px solid'>choice</Badge>
          This order qualifies for free return
        </Text>
      </Flex>
      <br />
      <Flex align="center" justify="space-between" w="100%" mb="20px" px="200px">
        <Flex direction="column" align="center">
          <Box
            borderRadius="50%"
            bg={currentStep >= 1 ? 'red.500' : 'gray.300'}
            color="white"
            width="40px"
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            1
          </Box>
          <Text mt="10px">Select article</Text>
        </Flex>

        <Box
          flex="1"
          height="2px"
          mb='35px'
          bg={currentStep >= 2 ? 'red.500' : 'gray.300'}
        />
        <Flex direction="column" align="center">
          <Box
            borderRadius="50%"
            bg={currentStep >= 2 ? 'red.500' : 'gray.300'}
            color={currentStep >= 2 ? 'white' : 'black'}
            width="40px"
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            2
          </Box>
          <Text mt="10px">Select method</Text>
        </Flex>
      </Flex>
      <br />
      {currentStep === 1 &&
        <Box width='50%'>
          <Flex align="center" mb="20px" gap='20px'>
            <Box>
              <Image
                src={order?.product_image}
                alt="Product Image"
                boxSize="100px"
                width='130px'
                height='90px'
                minW='100px'
                minH='70px'
                border='gray 1px solid'
                borderRadius='10px'
              />
            </Box>
            <Box>
              <Text fontWeight="600">{order?.product_name}</Text>
              <Text fontSize="sm">{order?.productDescription}</Text>
              <Flex align="center" mt="10px">
                <NumberGroupInput
                  defaultValue={order?.quantity}
                  onChange={(value) => ('')}
                />
              </Flex>
            </Box>
          </Flex>

          {/* Reason for Return */}
          <Select
            placeholder="Please select"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            mb="20px"
          >
            {refundCategory && refundCategory.length > 0 ? (
              refundCategory.map((category) => (
                <optgroup key={category.id} label={category.name}>
                  {/* Main category */}
                  <option value={category.id}>{category.name}</option>

                  {/* Subcategories */}
                  {category.subcategory && category.subcategory.length > 0 && category.subcategory.map((sub) => (
                    <option key={sub.id} value={sub.id}>&nbsp;&nbsp;&nbsp;{sub.name}</option>
                  ))}
                </optgroup>
              ))
            ) : (
              <option value="">No refund categories available</option>
            )}
          </Select>

          {/* Upload Section */}
          <Text mb="10px" fontWeight="600">Upload a picture/video</Text>
          <Input type="file" accept="image/*" onChange={handleFileUpload} mb="10px" />
          <Input type="file" accept="video/*" onChange={handleFileUpload} mb="10px" />

          <Textarea
            placeholder="Please describe your problem."
            value={problemDescription}
            onChange={(e) => setProblemDescription(e.target.value)}
            mb="20px"
          />
        </Box>}
      {currentStep === 2 &&
        <>
          <Box p={6} borderRadius="md" boxShadow="md">
            <Heading as="h4" size="lg" mb={4}>
              Select a refund method
            </Heading>

            {/* Refund Method */}
            <RadioGroup onChange={setRefundMethod} value={refundMethod}>
              <Stack spacing={4}>
                {/* Bonus option */}
                <HStack align="start">
                  <Radio value="Bonus" mt='6px' />
                  <Box>
                    <Text fontWeight="bold" fontSize="lg">Bonus</Text>
                    <Text color="red.500" fontWeight="bold">Arrives in seconds | Safe & secure | No expiry</Text>
                    <Text fontSize="sm" color='gray'>
                      By clicking Submit, you agree to the Cash Refund into Bonus, subject to the Refund terms and
                      Conditions. This operation is irreversible.
                    </Text>
                  </Box>
                </HStack>

                {/* Original payment method option */}
                <HStack align="start">
                  <Radio value="Original payment" mt='6px' />
                  <Box>
                    <Text fontWeight="bold" fontSize="lg">Original payment method</Text>
                    <Text fontSize="sm" fontWeight="bold">5326 12******7991</Text>
                    <Text fontSize="sm" color='gray'>
                      Refunds are issued within 3-14 business days (some payment methods may take up to 20 business days,
                      depending on the bank or financial institution).
                    </Text>
                  </Box>
                </HStack>
              </Stack>
            </RadioGroup>
          </Box>

          {/* Estimated refund */}
          <Box p={6} borderRadius="md" boxShadow="md" mt='20px'>
            <Text fontSize="lg" fontWeight="bold">Estimated refund amount: US$21.18</Text>
          </Box>

          {/* Email confirmation */}
          <Box p={6} borderRadius="md" boxShadow="md" mt='20px'>
            <FormControl isRequired>
              <FormLabel>Confirm your email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
              <Text mt='5px' fontSize="sm" color='gray'>Confirm your email to receive return labels, return updates, and refund notifications.</Text>
            </FormControl>
          </Box>
        </>
      }
      <br />
      <br />
      {/* Next Step Button */}
      <Flex justifyContent="center">
        {currentStep === 2 &&
          <Button
            mr='10px'
            variant='secondary'
            width="100%" maxW='300px' borderRadius='20px'
            onClick={moveToFirstStep}
          >
            Back
          </Button>}
        <Button
          width="100%" maxW='300px' borderRadius='20px'
          onClick={currentStep === 1 ? moveToSecondStep : submitRefundOrder}
        >
          {currentStep === 1 ? 'Next Step' : 'Submit'}
        </Button>
      </Flex>
    </Box>
  );
};

export default ReturnOrder;
