// import React, { useEffect, useState } from 'react';
// import { Button, HStack, Input, useNumberInput } from '@chakra-ui/react';

// const NumberGroupInput = ({ onChange }) => {
//   const [value, setValue] = useState(1);

//   const onDecrementClick = () => {
//     if (value <= 1) return;
//     setValue(parseInt(value) - 1);
//     onChange(parseInt(value) - 1);
//   };
//   const onIncrementClick = () => {
//     setValue(parseInt(value) + 1);
//     onChange(parseInt(value) + 1);
//   };
//   return (
//     <HStack gap={0}>
//       <Button
//         padding='0'
//         maxWidth='15px'
//         height={'25px'}
//         width={'15px'}
//         color='black'
//         background='#ddd'
//         onClick={onDecrementClick}
//       >
//         -
//       </Button>
//       <Input
//         onChange={(e) => {
//           if (e.target.value < 1) return;
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         value={value}
//         textAlign={'center'}
//         padding='0'
//         maxWidth='35px'
//         minWidth='30px'
//         width={'35px'}
//         height={'30px'}
//         border='transparent'
//       />
//       <Button
//         padding='0'
//         maxWidth='15px'
//         height={'25px'}
//         width={'15px'}
//         color='black'
//         background='#ddd'
//         onClick={onIncrementClick}
//       >
//         +
//       </Button>
//     </HStack>
//   );
// };

// export default NumberGroupInput;




import React, { useEffect, useState } from 'react';
import { Button, HStack, Input } from '@chakra-ui/react';

const NumberGroupInput = ({ value: initialValue = 1, onChange }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue); 
  }, [initialValue]);

  const onDecrementClick = () => {
    if (value <= 1) return;
    const newValue = value - 1;
    setValue(newValue);
    onChange(newValue);
  };

  const onIncrementClick = () => {
    const newValue = value + 1;
    setValue(newValue);
    onChange(newValue);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const parsedValue = parseInt(inputValue, 10);

    if (!isNaN(parsedValue) && parsedValue > 0) {
      setValue(parsedValue);
      onChange(parsedValue);
    } else if (inputValue === '') {
      setValue(''); 
    }
  };

  return (
    <HStack gap={0}>
      <Button
        padding='0'
        maxWidth='15px'
        height={'25px'}
        width={'15px'}
        color='black'
        background='#ddd'
        onClick={onDecrementClick}
        // isDisabled={value <= 1}
      >
        -
      </Button>
      <Input
        onChange={handleInputChange}
        value={value}
        textAlign={'center'}
        padding='0'
        maxWidth='35px'
        minWidth='30px'
        width={'35px'}
        height={'30px'}
        border='transparent'
        type='number'
        min='1'
      />
      <Button
        padding='0'
        maxWidth='15px'
        height={'25px'}
        width={'15px'}
        color='black'
        background='#ddd'
        onClick={onIncrementClick}
      >
        +
      </Button>
    </HStack>
  );
};

export default NumberGroupInput;
