import React from 'react';

import HomeIcon from '../icons/home-icon';
import { Flex, Image } from '@chakra-ui/react';
import WishlistIcon from '../icons/wishlist-icon';
import FilterIcon from '../icons/filter-icon';
import CartIcon from '../icons/cart-icon';
import UserIcon from '../icons/user-icon';
import { NavLink, useNavigate } from 'react-router-dom';
import HeartIcon from '../icons/heart-icon';
import UserIconActive from '../icons/user-icon-active';
import HomeIconActive from '../icons/home-icon-active';

import home from '../../assets/img/icons/home.svg';
import homeRed from '../../assets/img/icons/homeRed.svg';
import heart from '../../assets/img/icons/heart.svg';
import heartRed from '../../assets/img/icons/ heartRed.svg';
import filter from '../../assets/img/icons/filter.svg';
import filterRed from '../../assets/img/icons/filterRed.svg';
import cart from '../../assets/img/icons/cart.svg';
import cartRed from '../../assets/img/icons/cartRed.svg';
import user from '../../assets/img/icons/user.svg';
import userRed from '../../assets/img/icons/userRed.svg';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty';
import { setOnLoginOpen } from '../../redux/homeSlice';

const BottomNavigation = ({ index, setIndex }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);

  const iconSize = '24px';

  return (
    <Flex
      bg='#FFFFFF'
      pos={'fixed'}
      bottom={'-1px'}
      zIndex={100}
      boxShadow={'0px -1px 1px 0px #00000029'}
      padding={'14px 24px'}
      right={0}
      left={0}
      width={'100%'}
      justify={'space-between'}
    >
      {/* <NavLink to='/'>
        {({ isActive, isPending, isTransitioning }) =>
          isActive ? <HomeIconActive /> : <HomeIcon />
        }
      </NavLink>

      <Flex cursor={'pointer'} align={'center'} justify={'center'}>
        <HeartIcon />
      </Flex>
      <Flex
        onClick={() => {
          dispatch(setIsCategoryOpen(!isCategoryOpen));
        }}
        cursor={'pointer'}
        align={'center'}
        justify={'center'}
      >
        <FilterIcon />
      </Flex>
      <Flex cursor={'pointer'} align={'center'} justify={'center'}>
        <CartIcon />
      </Flex>

      <NavLink to='/account'>
        {({ isActive, isPending, isTransitioning }) =>
          isActive ? <UserIconActive /> : <UserIcon />
        }
      </NavLink> */}

      <NavLink to='/'>
        {({ isActive }) => (
          <Image
            src={isActive ? homeRed : home}
            alt='Home'
            boxSize={iconSize}
            cursor='pointer'
            className='noSelect'
          />
        )}
      </NavLink>

      <NavLink to='/wishlist'>
        {({ isActive }) => (
          <Image
            src={isActive ? heartRed : heart}
            alt='Wishlist'
            boxSize={iconSize}
            cursor='pointer'
            className='noSelect'
          />
        )}
      </NavLink>

      <Flex cursor={'pointer'} align={'center'} justify={'center'}>
        <Image
          src={filter}
          alt='Filter'
          boxSize={iconSize}
          className='noSelect'
        />
      </Flex>

      <NavLink to='/product/cart'>
        {({ isActive }) => (
          <Image
            src={isActive ? cartRed : cart}
            alt='Cart'
            boxSize={iconSize}
            cursor='pointer'
            className='noSelect'
          />
        )}
      </NavLink>

      {isEmpty(userData) ? (
        <>
          <Flex
            onClick={() => dispatch(setOnLoginOpen(true))}
            className='noSelect'
            cursor={'pointer'}
            align={'center'}
            justify={'center'}
          >
            <Image
              src={user}
              alt='Account'
              boxSize={iconSize}
              cursor='pointer'
              className='noSelect'
            />
          </Flex>
        </>
      ) : (
        <NavLink to='/account'>
          {({ isActive }) => (
            <Image
              src={isActive ? userRed : user}
              alt='Account'
              boxSize={iconSize}
              cursor='pointer'
              className='noSelect'
            />
          )}
        </NavLink>
      )}
    </Flex>
  );
};

export default BottomNavigation;
