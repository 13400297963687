import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsCategoryOpen,
  setSubCategories,
  setSubSubCategories,
} from '../../redux/homeSlice';
import { useNavigate } from 'react-router-dom';
import styles from './sub-categories.module.scss';
import { setFilters } from '../../redux/productSlice';

const SubCategories = ({ title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subCategories, subSubCategories } = useSelector(
    (state) => state.home
  );
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [isBiggerThan767] = useMediaQuery('(min-width: 767px)');
  return (
    <Flex className={styles.sub_parent}>
      <Flex
        display={isSmallerThan767 ? 'none' : 'flex'}
        flexDir='column'
        width='auto'
      >
        <Text
          color={'#F41106'}
          fontWeight={600}
          textTransform={'uppercase'}
          mb='10px'
          pl={10}
        >
          {title}
        </Text>
        <Box
          overflowX={'hidden'}
          maxHeight={isSmallerThan767 ? '100%' : '465px'}
          minH={'465px'}
          overflowY={'auto'}
          borderRight={'1px solid #E2E2E2'}
        >
          <SimpleGrid
            columns={isBiggerThan767 ? 4 : 5}
            spacing={'20px'}
            pr={'20px'}
            w={'auto'}
            pl='40px'

            // mt='38px'
          >
            {subCategories.length === 0 ? (
              <Spinner />
            ) : (
              subCategories.map((item, index) => (
                <Flex
                  flexDir={'column'}
                  align={'center'}
                  textAlign={'center'}
                  mb='25px'
                  onClick={() => {
                    dispatch(setIsCategoryOpen(false));
                    navigate(`/product/category/${item.id}/${item.name}`);
                  }}
                >
                  <Flex
                    borderRadius={'50%'}
                    align={'center'}
                    justify={'center'}
                    border={item.isActive ? '1.5px solid #F41106' : 'none'}
                    bg={'#F5F5F5'}
                    height={'100px'}
                    width={'100px'}
                    padding={'12.5px 30px'}
                    onMouseEnter={() => {
                      const temp = subCategories.map(
                        (innerItem, innerIndex) => ({
                          ...innerItem,
                          isActive: innerIndex === index,
                        })
                      );

                      dispatch(setSubCategories(temp));
                      dispatch(setSubSubCategories(item.subcat_data));
                    }}
                    onMouseLeave={() => {
                      const temp = subCategories.map(
                        (innerItem, innerIndex) => ({
                          ...innerItem,
                          isActive: innerIndex === index,
                        })
                      );

                      dispatch(setSubCategories(temp));
                    }}
                    cursor={'pointer'}
                  >
                    <Image
                      height={'97px'}
                      // w={'60px'}
                      minW={'97px'}
                      border={'1px solid #F5F5F5'}
                      borderRadius={'50%'}
                      src={
                        item.image ||
                        require('../../assets/img/product/no-image.png')
                      }
                    />
                  </Flex>

                  <Tooltip label={item.name} aria-label='A tooltip'>
                    <Text
                      color={'#000'}
                      fontWeight={700}
                      fontSize={'14px'}
                      lineHeight={'20px'}
                      mt={'15.59px'}
                      whiteSpace={'nowrap'}
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                      maxW={'100px'}
                    >
                      {item.name}
                    </Text>
                  </Tooltip>
                </Flex>
              ))
            )}
          </SimpleGrid>
        </Box>
      </Flex>
      <Box
        w={isSmallerThan767 ? '100%' : '50%'}
        pl={isSmallerThan767 ? '5px' : '20px'}
        className={styles.sub_child}
      >
        {/* <Text color={'#F41106'} textTransform={'uppercase'} mb='20px'>
          {title}
        </Text> */}
        <Box
          overflowX={'hidden'}
          minH={'465px'}
          maxHeight={isSmallerThan767 ? '100%' : '500px'}

          // spacing={'20px'}
          // pr={'20px'}
          // w={'auto'}
          // pl='40px'
          // borderRight={'1px solid #E2E2E2'}
        >
          {' '}
          {subCategories.length === 0 ? (
            <Spinner />
          ) : (
            subCategories.map((item, index) => (
              <Flex flexDir={'column'} mb='15px'>
                <Text
                  fontSize={isSmallerThan767 ? '14px' : '16px'}
                  fontWeight={600}
                  mb='10px'
                >
                  {item.name}
                </Text>
                <SimpleGrid
                  columns={isSmallerThan767 ? 3 : 5}
                  spacing={isSmallerThan767 ? '10px' : '20px'}
                >
                  {item.subcat_data.map((catItem, catIndex) => (
                    <Flex
                      flexDir={'column'}
                      align={'center'}
                      textAlign={'center'}
                      mb='25px'
                      onClick={() => {
                        dispatch(setIsCategoryOpen(false));
                        dispatch(
                          setFilters({
                            sale: false,
                            isFreeShipping: false,
                            rating: false,
                            min: '',
                            max: '',
                            best_match: 1,
                            order: 0,
                            isPrice: 0,
                          })
                        );
                        navigate(
                          `/product/listing?category_id=${
                            catItem.id
                          }&title=${title.replace('&', 'and')}`
                        );
                      }}
                      cursor={'pointer'}
                    >
                      <Flex
                        borderRadius={'50%'}
                        align={'center'}
                        justify={'center'}
                        border={
                          catItem.isActive ? '1.5px solid #F41106' : 'none'
                        }
                        bg={'#F5F5F5'}
                        height={isSmallerThan767 ? '66px' : '100px'}
                        width={isSmallerThan767 ? '66px' : '100px'}
                        padding={'12.5px 30px'}
                        onMouseEnter={() => {
                          const temp = subSubCategories.map(
                            (innerItem, innerIndex) => ({
                              ...innerItem,
                              isActive: innerIndex === catIndex,
                            })
                          );

                          dispatch(setSubSubCategories(temp));
                        }}
                        onMouseLeave={() => {
                          const temp = subSubCategories.map(
                            (innerItem, innerIndex) => ({
                              ...innerItem,
                              isActive: innerIndex === catIndex,
                            })
                          );

                          dispatch(setSubSubCategories(temp));
                        }}
                      >
                        <Image
                          height={isSmallerThan767 ? '60px' : '97px'}
                          w={isSmallerThan767 ? '60px' : '97px'}
                          minW={isSmallerThan767 ? '60px' : '97px'}
                          border={'1px solid #F5F5F5'}
                          borderRadius={'50%'}
                          src={
                            catItem.image ||
                            require('../../assets/img/product/no-image.png')
                          }
                        />
                      </Flex>

                      <Tooltip label={catItem.name} aria-label='A tooltip'>
                        <Text
                          color={'#000'}
                          fontWeight={400}
                          fontSize={isSmallerThan767 ? '12px' : '14px'}
                          lineHeight={'20px'}
                          mt={'15.59px'}
                          whiteSpace={'nowrap'}
                          overflow={'hidden'}
                          textOverflow={'ellipsis'}
                          maxW={isSmallerThan767 ? '60px' : '100px'}
                        >
                          {catItem.name}
                        </Text>
                      </Tooltip>
                    </Flex>
                  ))}
                </SimpleGrid>
              </Flex>
            ))
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default SubCategories;
