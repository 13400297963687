/* eslint-disable default-case */
import { Box, Flex, Select, Text, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsHandler } from '../../redux/homeSlice';
import { useQuery } from '../../hooks/useQuery';
import { FaFilter, FaSortDown, FaSortUp } from 'react-icons/fa';
import { setFilters } from '../../redux/productSlice';

const SortBy = ({ onFilterOpen }) => {
  const dispatch = useDispatch();

  const { filters } = useSelector((state) => state.product);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  const [isBestMatch, setIsBestMatch] = useState(true);
  const [isOrder, setIsOrder] = useState(false);
  const [isPrice, setIsPrice] = useState(false);
  const [priceClicked, setPriceClicked] = useState(false);

  useEffect(() => {
    if (filters.best_match === 1) {
      setIsBestMatch(true);
    }
    if (filters.order === 0) {
      setIsOrder(false);
    }
    if (filters.isPrice === 0) {
      setIsPrice(false);
    }
  }, [filters]);

  return (
    <>
      {!isSmallerThan767 ? (
        <>
          {' '}
          <Flex align={'center'} gap={'10px'} justify={'flex-end'} mb='10px'>
            <Text>Sort By</Text>
            <Flex>
              <Flex
                bg={isBestMatch ? '#f41106' : 'white'}
                color={isBestMatch ? '#fff' : '#000'}
                border={'1px solid #ddd'}
                p={'5px 7px'}
                borderTopLeftRadius={'20px'}
                borderBottomLeftRadius={'20px'}
                width={'130px'}
                align={'center'}
                justify={'center'}
                cursor={'pointer'}
                fontSize={'.87rem'}
                fontWeight={600}
                onClick={() => {
                  setIsBestMatch(true);
                  setIsOrder(false);
                  setIsPrice(false);
                  setPriceClicked(false);
                  dispatch(
                    setFilters({
                      ...filters,
                      best_match: 1,
                      order: 0,
                      isPrice: 0,
                    })
                  );
                }}
              >
                Best Match
              </Flex>
              <Flex
                bg={isOrder ? '#f41106' : 'white'}
                color={isOrder ? '#fff' : '#000'}
                align={'center'}
                justify={'center'}
                width={'130px'}
                border={'1px solid #ddd'}
                p={'5px 7px'}
                cursor={'pointer'}
                fontSize={'.87rem'}
                fontWeight={600}
                onClick={() => {
                  setIsBestMatch(false);
                  setIsOrder(true);
                  setIsPrice(false);
                  setPriceClicked(false);
                  dispatch(
                    setFilters({
                      ...filters,
                      best_match: 0,
                      order: 1,
                      isPrice: 0,
                    })
                  );
                }}
              >
                Top sellers
              </Flex>
              <Flex
                bg={priceClicked ? '#f41106' : 'white'}
                color={priceClicked ? '#fff' : '#000'}
                align={'center'}
                justify={'center'}
                width={'130px'}
                border={'1px solid #ddd'}
                p={'5px 7px'}
                borderTopRightRadius={'20px'}
                borderBottomRightRadius={'20px'}
                cursor={'pointer'}
                fontSize={'.87rem'}
                fontWeight={600}
                onClick={() => {
                  setIsBestMatch(false);
                  setIsOrder(false);
                  setIsPrice(!isPrice);
                  setPriceClicked(true);
                  dispatch(
                    setFilters({
                      ...filters,
                      best_match: 0,
                      order: 0,
                      isPrice: isPrice ? 0 : 1,
                    })
                  );
                }}
              >
                <Flex gap={'10px'} align={'center'} justify={'space-between'}>
                  Price
                  <Flex flexDir={'column'}>
                    <Box mb={'-17px'}>
                      <FaSortUp
                        color={priceClicked && !isPrice ? '#fff' : ' #000'}
                        size={'16px'}
                      />
                    </Box>
                    <Box>
                      <FaSortDown
                        color={priceClicked && isPrice ? '#fff' : ' #000'}
                        size={'16px'}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex justify={'space-between'} align={'center'} mb='20px'>
          <Box width={'200px'}>
            <Select
              onChange={(e) => {
                if (e.target.value === 'bestMatch') {
                  dispatch(
                    setFilters({
                      ...filters,
                      best_match: 1,
                      isPrice: 0,
                      order: 0,
                    })
                  );
                } else if (e.target.value === 'order') {
                  dispatch(
                    setFilters({
                      ...filters,
                      best_match: 0,
                      isPrice: 0,
                      order: 1,
                    })
                  );
                } else if (e.target.value === 'priceLowToHigh') {
                  dispatch(
                    setFilters({
                      ...filters,
                      best_match: 0,
                      isPrice: 1,
                      order: 0,
                    })
                  );
                } else if (e.target.value === 'priceHighToLow') {
                  dispatch(
                    setFilters({
                      ...filters,
                      best_match: 0,
                      isPrice: 0,
                      order: 0,
                    })
                  );
                }
              }}
            >
              <option value='bestMatch'>Best Match</option>
              <option value='order'>Top sellers</option>
              <option value='priceLowToHigh'>Price Low to High</option>
              <option value='priceHighToLow'>Price High to Low</option>
            </Select>
          </Box>
          <Flex onClick={onFilterOpen} gap={'10px'} align={'center'}>
            <FaFilter /> <Text>Filter</Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default SortBy;
