import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Skeleton,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Categories = () => {
  const navigate = useNavigate();
  const {
    homeData: { top_categories },
    isLoading,
  } = useSelector((state) => state.home);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const _topCategories = isSmallerThan767
    ? top_categories?.slice(0, 12)
    : top_categories;
  return (
    <SimpleGrid
      ml='-5px'
      columns={isSmallerThan767 ? 4 : 7}
      spacing={isSmallerThan767 ? '11px' : '40px'}
    >
      {isLoading
        ? [0, 1, 2, 3, 4, 5, 6].map((item) => (
            <Skeleton
              bg='#F5F5F5'
              height={isSmallerThan767 ? '75px' : '135px'}
              w={isSmallerThan767 ? '75px' : '135px'}
              borderRadius={'50%'}
            />
          ))
        : _topCategories?.map((item) => (
            <Box textAlign={'center'}>
              <Flex
                align={'center'}
                justify={'center'}
                cursor={'pointer'}
                onClick={() =>
                  navigate(`/product/category/${item.id}/${item.name}`)
                }
                margin={'0 auto'}
              >
                <Image
                  height={isSmallerThan767 ? '75px' : '124px'}
                  w={isSmallerThan767 ? '75px' : '124px'}
                  minW={isSmallerThan767 ? '75px' : '124px'}
                  _hover={{
                    border: '2.5px solid #F41106',
                    filter:
                      'drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05)) drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.05)) drop-shadow(1px 10px 10px rgba(0, 0, 0, 0.04)) drop-shadow(2px 22px 13px rgba(0, 0, 0, 0.03)) drop-shadow(4px 40px 16px rgba(0, 0, 0, 0.01)) drop-shadow(6px 62px 17px rgba(0, 0, 0, 0.00))',
                  }}
                  src={
                    item.image ||
                    require('../../assets/img/product/no-image.png')
                  }
                  borderRadius={'50%'}
                />
              </Flex>

              <Text
                color={'#000'}
                fontWeight={700}
                fontSize={isSmallerThan767 ? '10px' : '16px'}
                lineHeight={isSmallerThan767 ? '15px' : '20px'}
                mt={'20px'}
              >
                {item.name}
              </Text>
            </Box>
          ))}
    </SimpleGrid>
  );
};

export default Categories;
